// Import the functions you need from the SDKs you need
import { getAuth } from '@firebase/auth';
import { getStorage } from '@firebase/storage';
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';


// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyBpMnyB7lIUNU0eKuQ6XyeBdlPYfN9aWzI",
    authDomain: "ocr-hasa.firebaseapp.com",
    projectId: "ocr-hasa",
    storageBucket: "ocr-hasa.appspot.com",
    messagingSenderId: "622838420705",
    appId: "1:622838420705:web:693d9c94b62530c2d053a0",
    measurementId: "G-T3NP97Q354"
  };

export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const storage = getStorage(app);
export const db = getFirestore(app);

