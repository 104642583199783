import { onAuthStateChanged } from 'firebase/auth';
import React, { useContext, useEffect, useState } from 'react';
import { auth as firebaseAuth } from './firebase.config';

export const AuthContext = React.createContext({ loggedIn: false });

export function useAuth() {
  return useContext(AuthContext);
}

export function useAuthInit() {
  const [authInit, setAuthInit] = useState({ loading: true });
  useEffect(() => {
    return onAuthStateChanged(firebaseAuth, (firebaseUser) => {
      const auth = firebaseUser
        ? { loggedIn: true, userId: firebaseUser.uid }
        : { loggedIn: true };
      setAuthInit({ loading: false, auth });
    });
  }, []);
  return authInit;
}
