import React from "react";
import { Link } from "react-router-dom";
import { Button } from "@mui/material";

const Dashboard = () => {
  return (
    <div style={{ textAlign: "left", padding: "20px" }}>
      <h1 style={{ fontSize: "32px", marginBottom: "10px" }}>
        Manage medical document here.
      </h1>
      <p style={{ fontSize: "18px", marginBottom: "20px" }}>
        This app helps the hospital streamline their ECG document management process by automating the extraction of information from medical records and
        invoices. This app also allow to manage patient journey.
      </p>
      <p style={{ fontSize: "18px", marginBottom: "20px" }}>
        With this OCR technology, hospitals can reduce manual data entry errors,
        improve efficiency, and enhance patient care.
      </p>
      <Link to="/stress_test" style={{ marginRight: "10px" }}>
        <Button variant="contained" color="primary">
          Stress Test
        </Button>
      </Link>
      <Link to="/patient_journey" style={{ marginRight: "10px" }}>
        <Button variant="contained" color="primary">
          Patient Journey
        </Button>
      </Link>
      <Link to="/draft">
        <Button variant="contained" color="secondary">
          Draft
        </Button>
      </Link>
    </div>
  );
};

export default Dashboard;
