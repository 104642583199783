import React, { useState, useEffect } from "react";
import { collection, addDoc } from "firebase/firestore";
import { db } from "../../../config/firebase.config";
import { useNotification } from "../../../hooks/useNotification";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Grid from "@mui/material/Grid";

function Add() {
  const { showNotification } = useNotification();
  const [MRN, setMRN] = useState("");
  const [gender, setGender] = useState("");
  const [age, setAge] = useState("");
  const [address, setAddress] = useState("");
  const [patientJourney, setPatientJourney] = useState([]);
  const [reasonsDeceased, setReasonsDeceased] = useState("");
  const [bloodTest, setBloodTest] = useState("");
  const [echoDateResults, setEchoDateResults] = useState("");
  const [echoFindingResults, setEchoFindingResults] = useState("");

  // inpatient
  const [admitDate, setAdmitDate] = useState("");
  const [dischargeDate, setDischargeDate] = useState("");
  const [mainDiagnosis, setMainDiagnosis] = useState("");
  const [drugs, setDrugs] = useState("");
  const [procedure, setProcedure] = useState("");
  const [clinic, setClinic] = useState("");
  const [clinicDate, setClinicDate] = useState("");
  const [diagnosis, setDiagnosis] = useState("");

  const addPatientJourneyFirestore = async () => {
    try {
      const docRef = await addDoc(collection(db, "patientJourney"), {
        name: "Tokyo",
        country: "Japan",
      });
      console.log("Document written with ID: ", docRef.id);
      showNotification("Patient Journey added successfully");
    } catch (e) {
      console.error("Error adding document: ", e);
      showNotification("Patient Journey not added");
    }
  };

  //push to patientJourney array
  const addPatientJourney = async (data) => {};

  return (
    <>
      <Box
        component="form"
        sx={{
          "& > :not(style)": { m: 1, width: "60ch" },
        }}
        noValidate
        autoComplete
      >
        <TextField
          id="outlined-basic"
          label="MRN"
          variant="outlined"
          value={MRN}
          onInput={(e) => setMRN(e.target.value)}
        />
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Gender</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={gender}
            label="Gender"
            onChange={(e) => setGender(e.target.value)}
          >
            <MenuItem value={"Male"}>Male</MenuItem>
            <MenuItem value={"Gender"}>Gender</MenuItem>
            <MenuItem value={"Others"}>Others</MenuItem>
          </Select>
        </FormControl>
        <TextField
          id="outlined-basic"
          label="Age"
          variant="outlined"
          value={age}
          onInput={(e) => setAge(e.target.value)}
        />
        <TextField
          id="outlined-basic"
          label="Address"
          variant="outlined"
          fullWidth
          value={address}
          onInput={(e) => setAddress(e.target.value)}
        />
        <Box>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                id="outlined-basic"
                label="Admit Date"
                variant="outlined"
                fullWidth
                value={admitDate}
                onInput={(e) => setAdmitDate(e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="outlined-basic"
                label="Discharge Date"
                variant="outlined"
                fullWidth
                value={dischargeDate}
                onInput={(e) => setDischargeDate(e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="outlined-basic"
                label="Main Diagnosis"
                variant="outlined"
                fullWidth
                value={mainDiagnosis}
                onInput={(e) => setMainDiagnosis(e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="outlined-basic"
                label="Drugs"
                variant="outlined"
                fullWidth
                value={drugs}
                onInput={(e) => setDrugs(e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="outlined-basic"
                label="Procedure"
                variant="outlined"
                fullWidth
                value={procedure}
                onInput={(e) => setProcedure(e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="outlined-basic"
                label="Clinic"
                variant="outlined"
                fullWidth
                value={clinic}
                onInput={(e) => setClinic(e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="outlined-basic"
                label="Clinic Date"
                variant="outlined"
                fullWidth
                value={clinicDate}
                onInput={(e) => setClinicDate(e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="outlined-basic"
                label="Diagnosis"
                variant="outlined"
                fullWidth
                value={diagnosis}
                onInput={(e) => setDiagnosis(e.target.value)}
              />
            </Grid>
          </Grid>
          <br />
          <Button
            variant="contained"
            onClick={addPatientJourney}
            style={{ width: "100%" }}
          >
            Add patient journey
          </Button>
        </Box>
        <TextField
          id="outlined-basic"
          label="Reasons Deceased"
          variant="outlined"
          fullWidth
          value={reasonsDeceased}
          onInput={(e) => setReasonsDeceased(e.target.value)}
        />
        <TextField
          id="outlined-basic"
          label="Blood Test"
          variant="outlined"
          fullWidth
          value={bloodTest}
          onInput={(e) => setBloodTest(e.target.value)}
        />
        <TextField
          id="outlined-basic"
          label="Echo Date Results"
          variant="outlined"
          fullWidth
          value={echoDateResults}
          onInput={(e) => setEchoDateResults(e.target.value)}
        />
        <TextField
          id="outlined-basic"
          label="Echo Finding Results"
          variant="outlined"
          fullWidth
          value={echoFindingResults}
          onInput={(e) => setEchoFindingResults(e.target.value)}
        />
        <Button variant="contained" onClick={addPatientJourney}>
          Add
        </Button>
      </Box>
    </>
  );
}

export default Add;
