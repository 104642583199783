import React, { useState } from "react";
import {
  Card,
  CardContent,
  FormControl,
  TextField,
  Button,
  Typography,
  Grid,
  Box,
} from "@mui/material";
import { Link } from "react-router-dom";
import { createUserWithEmailAndPassword } from "@firebase/auth";
import { auth } from "../../../config/firebase.config";
import { useAuth } from "../../../config/auth";
import { Navigate } from "react-router-dom";
import AccountCircle from "@mui/icons-material/AccountCircle";
import { SUCCESS, ERROR } from "../../../config/constants";
import { useNotification } from "../../../hooks/useNotification";
import Ocrius from "../../../images/ocrius-white.png";

const Register = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [otp, setOtp] = useState("");
  const { loggedIn } = useAuth();
  const { showNotification } = useNotification();

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleRegister = async (auth, email, password) => {
    if (otp === "") {
      showNotification("Please enter OTP", ERROR);
      return;
    }
    if (otp == "990826") {
      try {
        const credential = await createUserWithEmailAndPassword(
          auth,
          email,
          password
        );
        console.log("credential:", credential);
        console.log("credential.user:", credential.user);
        showNotification("Registration Successful", SUCCESS);
      } catch (error) {
        console.log("error:", error);
        showNotification(
          "Registration unsuccessful, account already created",
          ERROR
        );
      }
    } else {
      showNotification("Invalid OTP", ERROR);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        backgroundColor: "rgba(0, 0, 0, 0.2)",
      }}
    >
      <Card
        style={{
          margin: "5px",
          padding: "10px",
          justifyContent: "center",
          display: "flex",
          flexDirection: "column",
          backgroundColor: "#418AF6",
        }}
      >
        <CardContent
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={Ocrius}
            alt="Ocrius Logo"
            style={{ height: "150px", marginBottom: "10px" }}
          />
          <Typography
            variant="h5"
            gutterBottom
            style={{
              textAlign: "center",
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
              color: "white",
            }}
          >
            Register &nbsp; <AccountCircle />
          </Typography>
          <Typography variant="caption" color="white">
            Register to create an account
          </Typography>
        </CardContent>
        <Grid container>
          <Grid xs={12} style={{ display: "flex", justifyContent: "center" }}>
            <Box
              style={{
                backgroundColor: "white",
                padding: "8px",
                borderRadius: "5px",
              }}
            >
              <FormControl>
                <span
                  style={{
                    fontSize: "18px",
                    fontWeight: "bold",
                    color: "#418AF6",
                  }}
                >
                  Email
                </span>
                <TextField value={email} placeholder="yourname@hospital.uitm.edu.my" onChange={handleEmailChange} />
              </FormControl>
            </Box>
          </Grid>

          <Grid
            xs={12}
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "10px",
            }}
          >
            <Box
              style={{
                backgroundColor: "white",
                padding: "8px",
                borderRadius: "5px",
              }}
            >
              <FormControl>
                <span
                  style={{
                    fontSize: "18px",
                    fontWeight: "bold",
                    color: "#418AF6",
                  }}
                >
                  Password
                </span>
                <TextField
                  type="password"
                  value={password}
                  onChange={handlePasswordChange}
                />
              </FormControl>
            </Box>
          </Grid>
          <Grid
            xs={12}
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "10px",
            }}
          >
            <Box
              style={{
                backgroundColor: "white",
                padding: "8px",
                borderRadius: "5px",
              }}
            >
              <FormControl>
                <span
                  style={{
                    fontSize: "18px",
                    fontWeight: "bold",
                    color: "#418AF6",
                  }}
                >
                  OTP from Hospital
                </span>
                <TextField
                  type="otp"
                  value={otp}
                  onChange={(e) => setOtp(e.target.value)}
                />
              </FormControl>
            </Box>
          </Grid>
        </Grid>
        <br />

        <Button
          type="submit"
          variant="contained"
          style={{
            backgroundColor: "#fff",
            color: "#418AF6",
            fontSize: "18px",
            marginBottom: "5px",
          }}
          onClick={() => handleRegister(auth, email, password)}
        >
          Register
        </Button>

        <Link to={"/login"}>
          <Button style={{ width: "100%", color: "white" }} variant="text">
            Login
          </Button>
        </Link>
      </Card>
    </div>
  );
};

export default Register;
