import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Link } from "react-router-dom";
// import patientData from "../../../store/patient_journey.json";
import patientData from "../../../store/new_data/combined.json"
import { Button, Stack } from "@mui/material";

function View() {
  const [rows, setRows] = React.useState([]);
  const columns = [
    { field: "MRN", headerName: "MRN", width: 130 },
    { field: "Gender", headerName: "Gender", width: 90 },
    { field: "Age", headerName: "Age", width: 90 },
    {
      field: "Address",
      headerName: "Address",
      width: 250,
    },
    {
      field: "NoOfVisits",
      headerName: "No of Visits",
      description: "This column has a value getter and is not sortable.",
      sortable: false,
      width: 300,
      valueGetter: (params) =>
        `Inpatient : ${
          params.row.Inpatient.length || "0"
        }, Outpatient : ${
          params.row.Outpatient.length || "0"
        }`,
    },
  ];

  React.useEffect(() => {
    const allData = [];
    patientData.patient.map((data) => {
      var inpatientTimeline = data.Inpatient.filter((value) =>
        value.toLowerCase().includes("admit")
      );
      var outpatientTimeline = data.Outpatient.filter((value) =>
        value.toLowerCase().includes("date")
      );
      allData.push({
        id: data.no,
        MRN: data.MRN,
        Gender: data.Gender,
        Age: data.Age,
        Address: data.Alamat,
        Inpatient: data.Inpatient,
        Outpatient: data.Outpatient,
        Timeline: [...inpatientTimeline, ...outpatientTimeline],
      });
    });
    setRows(allData);
  }, []);
  return (
    <>
      <Stack spacing={2} direction="row">
        {/* <Link to="/patient_journey/add">
          <Button variant="contained">Add</Button>
        </Link> */}
        <Link to="/patient_journey">
          <Button variant="contained">Back</Button>
        </Link>
      </Stack>
      <div style={{ height: 800, width: "100%" }}>
        <DataGrid
          rows={rows}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 25 },
            },
          }}
          checkboxSelection
        />
      </div>
    </>
  );
}

export default View;
