import React, { useEffect, useState } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";
import Box from "@mui/material/Box";



export default function DistributionByAgeGroup({ rows }) {

  const [data, setData] = useState([]);
  const [counter, setCounter] = useState(0);

  // cluster the age into 5 groups
//  data =  [
//     {
//       name: "< 20",
//       male: 50,
//       female: 20,
//     },
//     {
//       name: "20 to 40",
//       male: 300,
//       female: 138,
//     },
//     {
//       name: "40 to 60",
//       male: 480,
//       female: 238,
//     },
//     {
//       name: "60 >",
//       male: 780,
//       female: 308,
//     },
//   ]
  useEffect(() => {
  

      // cluster the age into five group and their gender started from 0-20, 21-40, 41-60, 61-80, 81-100
      let maleAgeGroup = [0, 0, 0, 0, 0];
      let femaleAgeGroup = [0, 0, 0, 0, 0];
      let othersAgeGroup = [0, 0, 0, 0, 0];
      rows.forEach((row) => {
        if(row.Gender == "M"){
          var age = parseInt(row.Age);
          if(age < 20){
            maleAgeGroup[0] += 1;
          }else if(age < 40){
            maleAgeGroup[1] += 1;
          }else if(age < 60){
            maleAgeGroup[2] += 1;
          }else if(age < 80){
            maleAgeGroup[3] += 1;
          }else{
            maleAgeGroup[4] += 1;
          }
        }
        else if(row.Gender == "F"){
          if(age < 20){
            femaleAgeGroup[0] += 1;
          }else if(age < 40){
            femaleAgeGroup[1] += 1;
          }else if(age < 60){
            femaleAgeGroup[2] += 1;
          }else if(age < 80){
            femaleAgeGroup[3] += 1;
          }else{
            femaleAgeGroup[4] += 1;
          }
        }
        else {
          if(age < 20){
            othersAgeGroup[0] += 1;
          }else if(age < 40){
            othersAgeGroup[1] += 1;
          }else if(age < 60){
            othersAgeGroup[2] += 1;
          }else if(age < 80){
            othersAgeGroup[3] += 1;
          }else{
            othersAgeGroup[4] += 1;
          }
        }
      });

      setData([
        {
          name: "< 20",
          male: maleAgeGroup[0],
          female : femaleAgeGroup[0],
          others : othersAgeGroup[0]
        },
        {
          name: "20 to 40",
          male: maleAgeGroup[1],
          female: femaleAgeGroup[1],
          others : othersAgeGroup[1]
        },
        {
          name: "40 to 60",
          male: maleAgeGroup[2],
          female: femaleAgeGroup[2],
          others : othersAgeGroup[2]
        },
        {
          name: "60 to 80",
          male: maleAgeGroup[3],
          female: femaleAgeGroup[3],
          others : othersAgeGroup[3]
        },
        {
          name: "80 >",
          male : maleAgeGroup[4],
          female : femaleAgeGroup[4],
          others : othersAgeGroup[4]
        },
      ]);
      

        // Stop the useEffect after 5 times
      if (counter < 5) {
        setCounter((prevCounter) => prevCounter + 1);
      }
  
    
  }, [rows, counter]);


  return (
    <Box
      style={{
        padding: "20px",
        margin: "10px",
        boxShadow: "0 5px 10px rgba(0, 0, 0, 0.1)",
        borderRadius: "10px",
      }}
    >
      <div
        style={{
          fontSize: "25px",
          alignItems: "center",
          alignContent: "center",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        Distribution by Age Group
        <br />
      </div>
      <LineChart
        width={700}
        height={300}
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Line
          type="monotone"
          dataKey="male"
          stroke="#8884d8"
          activeDot={{ r: 8 }}
        />
        <Line type="monotone" dataKey="female" stroke="#82ca9d" />
        <Line type="monotone" dataKey="others" stroke="#144131" />
      </LineChart>
    </Box>
  );
}
