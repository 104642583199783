import React, { useState } from "react";
import {
  Card,
  CardContent,
  FormControl,
  TextField,
  Button,
  Typography,
  Grid,
  Box,
} from "@mui/material";
import { Link } from "react-router-dom";
import { sendPasswordResetEmail, getAuth } from "firebase/auth";
import { useNotification } from "../../../hooks/useNotification";
import { ERROR, SUCCESS } from "../../../config/constants";

const ForgotPassword = () => {
  const auth = getAuth();
  const [email, setEmail] = useState("");
  const { showNotification } = useNotification();

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const triggerResetEmail = async () => {
    try {
      await sendPasswordResetEmail(auth, email);
      showNotification("Password reset email sent", SUCCESS);
      setTimeout(() => {
        // Code to be executed after 3 seconds
        showNotification("If your email is exist in our database, you will get a link to reset your password", SUCCESS);
      }, 3000);
    } catch (error) {
      showNotification(error.message, ERROR);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        backgroundColor: "rgba(0, 0, 0, 0.2)", // Set the background color to a darker shade
      }}
    >
      <Card
        style={{
          margin: "5px",
          padding: "20px",
          justifyContent: "center",
          display: "flex",
          flexDirection: "column",
          backgroundColor: "#418AF6",
        }}
      >
        <CardContent>
          <Typography
            variant="h5"
            gutterBottom
            style={{
              textAlign: "center",
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
              color: "white"
            }}
          >
            Reset Password
          </Typography>
          <Typography variant="caption" color="white">
            Send email to reset your password.
          </Typography>
        </CardContent>
        <Grid container>
          <Grid xs={12} style={{ display: "flex", justifyContent: "center" }}>
          <Box
              style={{
                backgroundColor: "white",
                padding: "8px",
                borderRadius: "5px",
              }}
            >
            <FormControl>
            <span style={{ fontSize: "18px", fontWeight: "bold", color: "#418AF6" }}>
                  Email
                </span>
              <TextField
                placeholder="yourname@hospital.uitm.edu.my"
                value={email}
                onChange={handleEmailChange}
              />
            </FormControl>
            </Box>
          </Grid>
        </Grid>
        <br />

        <Button
          type="submit"
          variant="contained"
          style={{ backgroundColor: "#fff", color: "#418AF6", fontSize: "18px", marginBottom: "5px"}}
          onClick={triggerResetEmail}
        >
          Submit
        </Button>

        <Link to={"/login"}>
          <Button style={{ width: "100%", color: "white" }} variant="text">
            Login
          </Button>
        </Link>
      </Card>
    </div>
  );
};

export default ForgotPassword;
