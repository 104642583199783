import React, { useEffect, useState } from "react";
import { PieChart, Pie, Sector, Cell, ResponsiveContainer } from "recharts";
import Box from "@mui/material/Box";

export default function GenderClassification({ male, female }) {
  const [data, setData] = useState([
    { name: "Male", value: male },
    { name: "Female", value: female },
  ]);

  const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];
  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
    return (
      <text
        x={x}
        y={y}
        fill="black"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
      >
        {`${(percent * 100).toFixed(0)}%`} {data[index].name}
      </text>
    );
  };

  useEffect(() => {
    setData([
      { name: "Male", value: male },
      { name: "Female", value: female },
    ]);
    return () => {
        console.log("cleaned up")
    }
  }, [male, female]);
  return (
    data && (
      <Box
        style={{
          padding: "20px",
          margin: "10px",
          boxShadow: "0 5px 10px rgba(0, 0, 0, 0.1)",
          borderRadius: "10px",
        }}
      >
        <div
          style={{
            fontSize: "25px",
            alignItems: "center",
            alignContent: "center",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          Gender Classification
        </div>
        <PieChart width={400} height={300}>
          <Pie
            data={data}
            cx={200}
            cy={140}
            innerRadius={50}
            outerRadius={90}
            fill="#8884d8"
            paddingAngle={5}
            dataKey="value"
            label={renderCustomizedLabel}
          >
            {data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
              />
            ))}
          </Pie>
        </PieChart>
      </Box>
    )
  );
}
