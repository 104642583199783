import JsonView from 'react18-json-view'
import 'react18-json-view/src/style.css'
import jsonObject from "../../../store/patient_journey.json"

import jsonRehab from "../../../store/new_data/processedRehab.json"
import jsonCardiology from "../../../store/new_data/processedCardiology.json"

import Button from '@mui/material/Button'
import { Link } from 'react-router-dom'
// If dark mode is needed, import `dark.css`.
// import 'react18-json-view/src/dark.css'

export default function ViewAll() {

  // combine json rehab and json cardiology and download it, filter by MRN

  function combineBothJSON (){

    var  jsonPatientCardiology = jsonCardiology.patient;
    var jsonPatientRehab = jsonRehab.patient;
    var count = 0;


    console.log("match count " + count);
    console.log("rehab count " + jsonPatientRehab.length);
    console.log("cardiology count " + jsonPatientCardiology.length);

    jsonPatientCardiology.forEach(( element) => {
      jsonPatientRehab.filter((item) => {
        if (item.MRN === element.MRN){
          // make the MRN to ""
          item.MRN = "";
          count = count + 1;
        }
      });
    });

    console.log(jsonPatientRehab);
    jsonPatientRehab.forEach(( element) => {
      if (element.MRN !== ""){
        jsonPatientCardiology.push(element);
      }
    });


    // download jsonPatientCardiology 
    const element = document.createElement("a");
    const file = new Blob([JSON.stringify(jsonPatientCardiology)], {type: 'text/plain'});
    element.href = URL.createObjectURL(file);
    element.download = "combined.json";
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();

    console.log("match count " + count);
    console.log("rehab count " + jsonPatientRehab.length);
    console.log("cardiology count " + jsonPatientCardiology.length);

  }

  // combineBothJSON();
  return (
    <>
    <Link to="/patient_journey">
        <Button variant="contained" color="primary">Back</Button>
    </Link>
    <br/>
    <span>This might take a minute as it contain large data</span>
    <JsonView src={jsonObject} />
    </>
  )
}
