import React, { useState, useEffect } from "react";
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid } from "recharts";
import Box from "@mui/material/Box";

export default function InpatientVersusOutpatient({ inpatient, outpatient, rehab }) {
  const colors = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042", "red", "pink"];
  const [data, setData] = useState([
    {
      name: "Inpatient",
      value: inpatient,
    },
    {
      name: "Outpatient",
      value: outpatient,
    },
    {
      name: "Rehab",
      value: rehab
    }
  ]);

  const getPath = (x, y, width, height) => {
    return `M${x},${y + height}C${x + width / 3},${y + height} ${
      x + width / 2
    },${y + height / 3}
        ${x + width / 2}, ${y}
        C${x + width / 2},${y + height / 3} ${x + (2 * width) / 3},${
      y + height
    } ${x + width}, ${y + height}
        Z`;
  };

  const TriangleBar = (props) => {
    const { fill, x, y, width, height } = props;

    return <path d={getPath(x, y, width, height)} stroke="none" fill={fill} />;
  };

  useEffect(() => {
    setData([
      {
        name: "Inpatient",
        value: inpatient,
      },
      {
        name: "Outpatient",
        value: outpatient,
      },
      {
        name: "Rehab",
        value: rehab
      }
    ]);
    return () => {
      console.log("cleaned up");
    };
  }, [inpatient, outpatient, rehab]);

  return (
    <Box
      style={{
        padding: "20px",
        margin: "10px",
        boxShadow: "0 5px 10px rgba(0, 0, 0, 0.1)",
        borderRadius: "10px",
      }}
    >
      <div
        style={{
          fontSize: "25px",
          alignItems: "center",
          alignContent: "center",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
         Inpatient vs Outpatient vs Rehab
      </div>
      <BarChart
        width={800}
        height={300}
        data={data}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Bar
          dataKey="value"
          fill="#8884d8"
          shape={<TriangleBar />}
          label={{ position: "top" }}
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={colors[index % 20]} />
          ))}
        </Bar>
      </BarChart>
    </Box>
  );
}
