import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  Button,
  Modal,
  TextField,
  Grid,
  Table,
  TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
} from "@mui/material";
import { doc, collection, addDoc, deleteDoc, updateDoc, getDocs } from "firebase/firestore";
import { db } from "../../../config/firebase.config";
import { useNotification } from "../../../hooks/useNotification";
import { SUCCESS, ERROR } from "../../../config/constants";
import { FORWARDER } from "../../../config/constants";
import  axios  from "axios";
function General() {
  const [open, setOpen] = useState(false);
  const [webhook, setWebhook] = useState("");
  const [tag, setTag] = useState("");
  const [webhooks, setWebhooks] = useState([]);
  const { showNotification } = useNotification();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleAddWebhook = async () => {
    if(!webhook || !tag) {
        showNotification("Webhook and tag are required", ERROR);
        return;
    }
    try {
      const docRef = await addDoc(collection(db, "webhooks"), {
        webhook: webhook,
        tag: tag,
        active: false,
        created: Date.now(),
      });
      showNotification("Webhook added", SUCCESS);
      console.log("Document written with ID: ", docRef.id);
      setOpen(false);
    } catch (error) {
      showNotification("Webhook not added", ERROR);
      console.error("Error adding document: ", error);
    }
  };

const handleDeleteWebhook = async (id) => {
    const confirmed = window.confirm("Are you sure you want to delete this webhook?");
    if (!confirmed) {
        return;
    }
    try {
        await deleteDoc(doc(db, "webhooks", id));
        showNotification("Webhook deleted", SUCCESS);
    } catch (error) {
        showNotification("Webhook not deleted", ERROR);
        console.error("Error deleting document: ", error);
    }
}

  const handleUpdateWebhook = async (id, status) => {
    try {
      await updateDoc(doc(db, "webhooks", id), {
        active: !status,
      });
      showNotification("Webhook updated", SUCCESS);
      getWebhooks();
    } catch (error) {
      showNotification("Webhook not updated", ERROR);
      console.error("Error updating document: ", error);
    }
  }

  const handleTestWebhook = async (id) => {
    try {
      const forwarderUrl = FORWARDER; // Replace with your forwarder URL
      const postData = {
        echo: "Webhook successfully received.",
        webhooks: webhooks,
      };
      const response = await axios.post(forwarderUrl, postData);
      console.log("Test successful:", response.data);
      showNotification("Test successful", SUCCESS);
    } catch (error) {
      console.error("Test failed:", error);
      showNotification("Test failed", ERROR);
    }
  }

  useEffect(() => {
    getWebhooks();
  }, []);

  const getWebhooks = async () => {
    const webhooksRef = collection(db, "webhooks");
    const snapshot = await getDocs(webhooksRef);
    const webhooks = snapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    setWebhooks(webhooks);
  };
  return (
    <>
      <Card>
        <CardContent>
          <h2>General</h2>
          <Button variant="contained" onClick={handleOpen}>
            Add Webhook
          </Button>
        </CardContent>
      </Card>
      <br/>
      <Card>
        <CardContent>
            <h2>Webhooks</h2>
            {/* Table of webhook using MUI */}
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                    <TableCell>Webhook</TableCell>
                    <TableCell align="right">Tag</TableCell>
                    <TableCell align="right">Status</TableCell>
                    <TableCell align="right">Action</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {webhooks.map((webhook) => (
                    <TableRow
                        key={webhook.id}
                        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                        <TableCell component="th" scope="row">
                        {webhook.webhook}
                        </TableCell>
                        <TableCell align="right">{webhook.tag}</TableCell>
                        <TableCell align="right">{webhook.active ? "Active" : "Inactive"}</TableCell>
                        <TableCell align="right">
                            <Button variant="contained" onClick={() => handleUpdateWebhook(webhook.id, webhook.active)}>{webhook.active ? "Deactivate" : "Activate"}</Button>
                            &nbsp;
                            <Button variant="outlined" color="error" onClick={() => handleDeleteWebhook(webhook.id)}>Delete</Button>
                            &nbsp;
                            <Button variant="outlined" onClick={() => handleTestWebhook(webhook.id)}>Test</Button>
                        </TableCell>
                    </TableRow>
                    ))}
                </TableBody>
                </Table>
            </TableContainer>
            </CardContent>
      </Card>

      <Modal open={open} onClose={handleClose}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
          }}
        >
          <div
            style={{
              backgroundColor: "white",
              padding: "20px",
              borderRadius: "5px",
              maxWidth: "400px",
            }}
          >
            <h2 style={{ marginBottom: "20px" }}>Add Webhook</h2>
            <Grid container>
              <Grid
                xs={12}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <TextField
                  label="Webhook"
                  value={webhook}
                  onChange={(e) => setWebhook(e.target.value)}
                  style={{ marginBottom: "10px" }}
                />
              </Grid>
              <Grid
                xs={12}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <TextField
                  label="Tag"
                  value={tag}
                  onChange={(e) => setTag(e.target.value)}
                  style={{ marginBottom: "10px" }}
                />
              </Grid>
              <Grid
                xs={12}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <Button variant="contained" onClick={handleAddWebhook}>
                  Save
                </Button>

                <Button
                  variant="outlined"
                  onClick={handleClose}
                  style={{ marginLeft: "10px" }}
                >
                  Close
                </Button>
              </Grid>
            </Grid>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default General;
