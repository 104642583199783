import React, { useState } from "react";
import {
  signInWithEmailAndPassword,
  signInWithPopup,
  GoogleAuthProvider,
} from "@firebase/auth";
import {
  Card,
  CardContent,
  FormControl,
  TextField,
  Button,
  Typography,
  Grid,
  Box,
  InputLabel,
} from "@mui/material";
import { Link } from "react-router-dom";
import { auth } from "../../../config/firebase.config";
import { useAuth } from "../../../config/auth";
import { Navigate } from "react-router-dom";
import AccountCircle from "@mui/icons-material/AccountCircle";
import { authActions } from "../../../store/auth";
import { useDispatch } from "react-redux";
import { SUCCESS, ERROR } from "../../../config/constants";
import { useNotification } from "../../../hooks/useNotification";
import Ocrius from "../../../images/ocrius-white.png";
import { Label } from "@mui/icons-material";

const LoginForm = () => {
  const { loggedIn } = useAuth();
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { showNotification } = useNotification();

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleLogin = async (auth, email, password) => {
    try {
      const credential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      console.log("credential:", credential);
      dispatch(authActions.login());
      showNotification("Login Successful", SUCCESS);
    } catch (error) {
      console.log("error:", error);
      showNotification("Passport login unsuccessful", ERROR);
    }
  };

  if (loggedIn) {
    return <Navigate to="/" />;
  }

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        backgroundColor: "rgba(0, 0, 0, 0.2)", // Set the background color to a darker shade
      }}
    >
      <Card
        style={{
          margin: "5px",
          padding: "10px",
          justifyContent: "center",
          display: "flex",
          flexDirection: "column",
          backgroundColor: "#418AF6",
        }}
      >
        <CardContent
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={Ocrius}
            alt="Ocrius Logo"
            style={{ height: "150px", marginBottom: "10px" }}
          />
          <Typography
            variant="h5"
            gutterBottom
            style={{
              textAlign: "center",
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
              color: "#fff",
            }}
          >
            Welcome &nbsp; <AccountCircle />
          </Typography>
          <Typography variant="caption" color="white">
            Sign in to your account
          </Typography>
        </CardContent>
        <Grid container spacing={2}>
          <Grid xs={12} style={{ display: "flex", justifyContent: "center", marginTop:"10px" }}>
            <Box
              style={{
                backgroundColor: "white",
                padding: "8px",
                borderRadius: "5px",
              }}
            >
              <FormControl>
                <span style={{ fontSize: "18px", fontWeight: "bold", color: "#418AF6" }}>
                  Email
                </span>
                <TextField value={email} onChange={handleEmailChange} />
              </FormControl>
            </Box>
          </Grid>

          <Grid
            xs={12}
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "10px",
            }}
          >
            <Box
              style={{
                backgroundColor: "white",
                padding: "8px",
                borderRadius: "5px",
              }}
            >
              <FormControl>
                <span style={{ fontSize: "18px", fontWeight: "bold", color: "#418AF6" }}>
                  Password
                </span>
                <TextField
                  type="password"
                  value={password}
                  onChange={handlePasswordChange}
                />
              </FormControl>
            </Box>
          </Grid>
        </Grid>
        <br />
        <br />

        <Button
          type="submit"
          variant="contained"
          style={{ backgroundColor: "#fff", color: "#418AF6", fontSize: "18px"}}
          onClick={() => handleLogin(auth, email, password)}
        >
          Login
        </Button>
        <br />
        <Link to={"/register"}>
          <Button style={{ width: "100%", color: "white"}} variant="text">
            register
          </Button>
        </Link>
        <Link to={"/forgot_password"}>
          <Button style={{ width: "100%", color: "white" }} variant="text">
            Forgot Password
          </Button>
        </Link>
      </Card>
    </div>
  );
};

export default LoginForm;
