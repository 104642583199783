import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";

import { FormControl, useFormControlContext } from "@mui/base/FormControl";
import { styled } from "@mui/system";
import clsx from "clsx";
import { TextareaAutosize as BaseTextareaAutosize } from "@mui/base/TextareaAutosize";
import { updateDoc, doc, getDoc } from "firebase/firestore";
import { db } from "../../../config/firebase.config";
import { SUCCESS, ERROR } from "../../../config/constants";
import { useNotification } from "../../../hooks/useNotification";
import { useNavigate, useParams } from "react-router-dom";
const Edit = () => {
  const { id } = useParams();
  const [institutionID, setInstitutionID] = useState("");
  const [faculty, setFaculty] = useState("");
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const [referring, setReferring] = useState("");
  const [medication, setMedication] = useState("");
  const [restingHR, setRestingHR] = useState("");
  const [restingSBP, setRestingSBP] = useState("");
  const [restingDBP, setRestingDBP] = useState("");
  const [targetHR, setTargetHR] = useState("");
  const [maxPredictedHR, setMaxPredictedHR] = useState("");
  const [percentMaxHR, setPercentMaxHR] = useState("");
  const [maxHR, setMaxHR] = useState("");
  const [maxSBP, setMaxSBP] = useState("");
  const [maxDBP, setMaxDBP] = useState("");
  const [protocol, setProtocol] = useState("");
  const [HRxBP, setHRxBP] = useState("");
  const [billingCode, setBillingCode] = useState("");
  const [worstcaseSTLevel, setWorstcaseSTLevel] = useState("");
  const [worstcaseSTSlope, setWorstcaseSTSlope] = useState("");
  const [totalExerciseTime, setTotalExerciseTime] = useState("");
  const [mets, setMets] = useState("");
  const [reasonsForTest, setReasonsForTest] = useState("");
  const [reasonsForEndingTest, setReasonsForEndingTest] = useState("");
  const [timestamp, setTimestamp] = useState("");

  const { showNotification } = useNotification();
  const navigate = useNavigate();

  const handleUpdate = async () => {
    const docRef = doc(db, "echo", id);
    const payload = {
      timestamp,
      institutionID,
      faculty,
      date,
      time,
      referring,
      medication,
      restingHR,
      restingSBP,
      restingDBP,
      targetHR,
      maxPredictedHR,
      percentMaxHR,
      maxHR,
      maxSBP,
      maxDBP,
      protocol,
      HRxBP,
      billingCode,
      worstcaseSTLevel,
      worstcaseSTSlope,
      totalExerciseTime,
      mets,
      reasonsForTest,
      reasonsForEndingTest,
    };
    try {
      await updateDoc(docRef, payload);
      showNotification("Update Successful", SUCCESS);
      navigate("/echo");
    } catch (error) {
      showNotification("Update unsuccessful", ERROR);
    }
  };
  useEffect(() => {
    const handleFetch = async () => {
      const docRef = doc(db, "echo", id);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        console.log("Document data:", docSnap.data());
        setTimestamp(new Date(docSnap.data().timestamp.seconds * 1000));
        setInstitutionID(docSnap.data().institutionID);
        setFaculty(docSnap.data().faculty);
        setDate(docSnap.data().date);
        setTime(docSnap.data().time);
        setReferring(docSnap.data().referring);
        setMedication(docSnap.data().medication);
        setRestingHR(docSnap.data().restingHR);
        setRestingSBP(docSnap.data().restingSBP);
        setRestingDBP(docSnap.data().restingDBP);
        setTargetHR(docSnap.data().targetHR);
        setMaxPredictedHR(docSnap.data().maxPredictedHR);
        setPercentMaxHR(docSnap.data().percentMaxHR);
        setMaxHR(docSnap.data().maxHR);
        setMaxSBP(docSnap.data().maxSBP);
        setMaxDBP(docSnap.data().maxDBP);
        setProtocol(docSnap.data().protocol);
        setHRxBP(docSnap.data().HRxBP);
        setBillingCode(docSnap.data().billingCode);
        setWorstcaseSTLevel(docSnap.data().worstcaseSTLevel);
        setWorstcaseSTSlope(docSnap.data().worstcaseSTSlope);
        setTotalExerciseTime(docSnap.data().totalExerciseTime);
        setMets(docSnap.data().mets);
        setReasonsForTest(docSnap.data().reasonsForTest);
        setReasonsForEndingTest(docSnap.data().reasonsForEndingTest);
      } else {
        // doc.data() will be undefined in this case
        console.log("No such document!");
      }
    };

    handleFetch();
  }, []);
  return (
    <Card sx={{ minWidth: 275 }}>
      <CardContent>
        <Typography variant="h3" component="div">
          Edit Data
        </Typography>
        <Typography variant="body2">
          <b>Created At</b> : {timestamp.toString()}
        </Typography>
        <Stack spacing={2} direction="row">
          <Button variant="contained">Upload a Document</Button>
        </Stack>
        <br />

        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={4}>
            <FormControl defaultValue="" required>
              <Label>Institution ID</Label>
              <input
                onInput={(e) => setInstitutionID(e.target.value)}
                value={institutionID}
                placeholder="institution ID"
                style={{
                  padding: "10px",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                  fontSize: "16px",
                  width: "100%",
                  boxSizing: "border-box",
                }}
              />
              <HelperText />
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl defaultValue="">
              <Label>Faculty</Label>
              <input
                onInput={(e) => setFaculty(e.target.value)}
                value={faculty}
                placeholder="faculty"
                style={{
                  padding: "10px",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                  fontSize: "16px",
                  width: "100%",
                  boxSizing: "border-box",
                }}
              />
              <HelperText />
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl defaultValue="">
              <Label>Date</Label>
              <input
                onInput={(e) => setDate(e.target.value)}
                value={date}
                placeholder="date"
                style={{
                  padding: "10px",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                  fontSize: "16px",
                  width: "100%",
                  boxSizing: "border-box",
                }}
              />
              <HelperText />
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl defaultValue="">
              <Label>Time</Label>
              <input
                onInput={(e) => setTime(e.target.value)}
                value={time}
                placeholder="time"
                style={{
                  padding: "10px",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                  fontSize: "16px",
                  width: "100%",
                  boxSizing: "border-box",
                }}
              />
              <HelperText />
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl defaultValue="">
              <Label>Referring</Label>
              <input
                onInput={(e) => setReferring(e.target.value)}
                value={referring}
                placeholder="referring"
                style={{
                  padding: "10px",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                  fontSize: "16px",
                  width: "100%",
                  boxSizing: "border-box",
                }}
              />
              <HelperText />
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl defaultValue="">
              <Label>Medication</Label>
              <Textarea
                aria-label="minimum height"
                minRows={3}
                placeholder="Minimum 3 rows"
                value={medication}
                onInput={(e) => setMedication(e.target.value)}
              />
              <HelperText />
            </FormControl>
          </Grid>
          <Grid item xs={2}>
            <FormControl defaultValue="">
              <Label>Resting HR</Label>
              <input
                onInput={(e) => setRestingHR(e.target.value)}
                value={restingHR}
                placeholder="resting HR"
                style={{
                  padding: "10px",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                  fontSize: "16px",
                  width: "100%",
                  boxSizing: "border-box",
                }}
              />
              <HelperText />
            </FormControl>
          </Grid>
          <Grid item xs={2}>
            <FormControl defaultValue="">
              <Label>Resting SBP</Label>
              <input
                onInput={(e) => setRestingSBP(e.target.value)}
                value={restingSBP}
                placeholder="resting SBP"
                style={{
                  padding: "10px",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                  fontSize: "16px",
                  width: "100%",
                  boxSizing: "border-box",
                }}
              />
              <HelperText />
            </FormControl>
          </Grid>
          <Grid item xs={2}>
            <FormControl defaultValue="">
              <Label>Resting DBP</Label>
              <input
                style={{
                  padding: "10px",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                  fontSize: "16px",
                  width: "100%",
                  boxSizing: "border-box",
                }}
                onInput={(e) => setRestingDBP(e.target.value)}
                value={restingDBP}
                placeholder="resting DBP"
              />
              <HelperText />
            </FormControl>
          </Grid>
          <Grid item xs={2}>
            <FormControl defaultValue="">
              <Label>Target HR</Label>
              <input
                style={{
                  padding: "10px",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                  fontSize: "16px",
                  width: "100%",
                  boxSizing: "border-box",
                }}
                onInput={(e) => setTargetHR(e.target.value)}
                value={targetHR}
                placeholder="target HR"
              />
              <HelperText />
            </FormControl>
          </Grid>
          <Grid item xs={2}>
            <FormControl defaultValue="">
              <Label>Max Predicted HR</Label>
              <input
                style={{
                  padding: "10px",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                  fontSize: "16px",
                  width: "100%",
                  boxSizing: "border-box",
                }}
                onInput={(e) => setMaxPredictedHR(e.target.value)}
                value={maxPredictedHR}
                placeholder="max predicted HR"
              />
              <HelperText />
            </FormControl>
          </Grid>
          <Grid item xs={2}>
            <FormControl defaultValue="">
              <Label>Percent Max HR</Label>
              <input
                style={{
                  padding: "10px",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                  fontSize: "16px",
                  width: "100%",
                  boxSizing: "border-box",
                }}
                onInput={(e) => setPercentMaxHR(e.target.value)}
                value={percentMaxHR}
                placeholder="percent max HR"
              />
              <HelperText />
            </FormControl>
          </Grid>
          <Grid item xs={2}>
            <FormControl defaultValue="">
              <Label>Max HR</Label>
              <input
                style={{
                  padding: "10px",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                  fontSize: "16px",
                  width: "100%",
                  boxSizing: "border-box",
                }}
                onInput={(e) => setMaxHR(e.target.value)}
                value={maxHR}
                placeholder="max HR"
              />
              <HelperText />
            </FormControl>
          </Grid>
          <Grid item xs={2}>
            <FormControl defaultValue="">
              <Label>Max SBP</Label>
              <input
                style={{
                  padding: "10px",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                  fontSize: "16px",
                  width: "100%",
                  boxSizing: "border-box",
                }}
                onInput={(e) => setMaxSBP(e.target.value)}
                value={maxSBP}
                placeholder="max SBP"
              />
              <HelperText />
            </FormControl>
          </Grid>
          <Grid item xs={2}>
            <FormControl defaultValue="">
              <Label>Max DBP</Label>
              <input
                style={{
                  padding: "10px",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                  fontSize: "16px",
                  width: "100%",
                  boxSizing: "border-box",
                }}
                onInput={(e) => setMaxDBP(e.target.value)}
                value={maxDBP}
                placeholder="max DBP"
              />
              <HelperText />
            </FormControl>
          </Grid>
          <Grid item xs={2}>
            <FormControl defaultValue="">
              <Label>Protocol</Label>
              <input
                style={{
                  padding: "10px",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                  fontSize: "16px",
                  width: "100%",
                  boxSizing: "border-box",
                }}
                onInput={(e) => setProtocol(e.target.value)}
                value={protocol}
                placeholder="protocol"
              />
              <HelperText />
            </FormControl>
          </Grid>
          <Grid item xs={2}>
            <FormControl defaultValue="">
              <Label>HR x BP</Label>
              <input
                style={{
                  padding: "10px",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                  fontSize: "16px",
                  width: "100%",
                  boxSizing: "border-box",
                }}
                onInput={(e) => setHRxBP(e.target.value)}
                value={HRxBP}
                placeholder="HR x BP"
              />
              <HelperText />
            </FormControl>
          </Grid>
          <Grid item xs={2}>
            <FormControl defaultValue="">
              <Label>Billing Code</Label>
              <input
                style={{
                  padding: "10px",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                  fontSize: "16px",
                  width: "100%",
                  boxSizing: "border-box",
                }}
                onInput={(e) => setBillingCode(e.target.value)}
                value={billingCode}
                placeholder="billing code"
              />
              <HelperText />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl defaultValue="">
              <Label>Worst Case ST Level</Label>
              <input
                style={{
                  padding: "10px",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                  fontSize: "16px",
                  width: "100%",
                  boxSizing: "border-box",
                }}
                onInput={(e) => setWorstcaseSTLevel(e.target.value)}
                value={worstcaseSTLevel}
                placeholder="worst case ST level"
              />
              <HelperText />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl defaultValue="">
              <Label>Worst Case ST Slope</Label>

              <input
                style={{
                  padding: "10px",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                  fontSize: "16px",
                  width: "100%",
                  boxSizing: "border-box",
                }}
                onInput={(e) => setWorstcaseSTSlope(e.target.value)}
                value={worstcaseSTLevel}
                placeholder="worst case ST slope"
              />
              <HelperText />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl defaultValue="">
              <Label>Total Exercise Time</Label>
              <input
                style={{
                  padding: "10px",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                  fontSize: "16px",
                  width: "100%",
                  boxSizing: "border-box",
                }}
                onInput={(e) => setTotalExerciseTime(e.target.value)}
                value={totalExerciseTime}
                placeholder="total exercise time"
              />
              <HelperText />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl defaultValue="">
              <Label>METS</Label>
              <input
                style={{
                  padding: "10px",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                  fontSize: "16px",
                  width: "100%",
                  boxSizing: "border-box",
                }}
                onInput={(e) => setMets(e.target.value)}
                value={mets}
                placeholder="METS"
              />
              <HelperText />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl defaultValue="">
              <Label>Reasons for Test</Label>
              <Textarea
                aria-label="minimum height"
                minRows={3}
                placeholder="Minimum 3 rows"
                value={reasonsForTest}
                onInput={(e) => setReasonsForTest(e.target.value)}
              />
              <HelperText />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl defaultValue="">
              <Label>Reasons for Ending Test</Label>

              <Textarea
                aria-label="minimum height"
                minRows={3}
                placeholder="Minimum 3 rows"
                value={reasonsForEndingTest}
                onInput={(e) => setReasonsForEndingTest(e.target.value)}
              />
              <HelperText />
            </FormControl>
          </Grid>
        </Grid>

        <br />
        <Button onClick={handleUpdate} variant="contained">
          Submit
        </Button>
      </CardContent>
    </Card>
  );
};

const Label = styled(({ children, className }) => {
  const formControlContext = useFormControlContext();
  const [dirty, setDirty] = React.useState(false);

  React.useEffect(() => {
    if (formControlContext?.filled) {
      setDirty(true);
    }
  }, [formControlContext]);

  if (formControlContext === undefined) {
    return <p>{children}</p>;
  }

  const { error, required, filled } = formControlContext;
  const showRequiredError = dirty && required && !filled;

  return (
    <p className={clsx(className, error || showRequiredError ? "invalid" : "")}>
      {children}
      {required ? " *" : ""}
    </p>
  );
})`
  font-family: "IBM Plex Sans", sans-serif;
  font-size: 0.875rem;
  margin-bottom: 4px;

  &.invalid {
    color: red;
  }
`;

const HelperText = styled((props) => {
  const formControlContext = useFormControlContext();
  const [dirty, setDirty] = React.useState(false);

  React.useEffect(() => {
    if (formControlContext?.filled) {
      setDirty(true);
    }
  }, [formControlContext]);

  if (formControlContext === undefined) {
    return null;
  }

  const { required, filled } = formControlContext;
  const showRequiredError = dirty && required && !filled;

  return showRequiredError ? <p {...props}>This field is required.</p> : null;
})`
  font-family: "IBM Plex Sans", sans-serif;
  font-size: 0.875rem;
`;

const blue = {
  100: "#DAECFF",
  200: "#b6daff",
  400: "#3399FF",
  500: "#007FFF",
  600: "#0072E5",
  900: "#003A75",
};

const grey = {
  50: "#F3F6F9",
  100: "#E5EAF2",
  200: "#DAE2ED",
  300: "#C7D0DD",
  400: "#B0B8C4",
  500: "#9DA8B7",
  600: "#6B7A90",
  700: "#434D5B",
  800: "#303740",
  900: "#1C2025",
};

const Textarea = styled(BaseTextareaAutosize)(
  ({ theme }) => `
    width: 100%;
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 8px 12px;
    border-radius: 8px;
    color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
    background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
    border: 1px solid ${theme.palette.mode === "dark" ? grey[700] : grey[200]};
    box-shadow: 0px 2px 2px ${
      theme.palette.mode === "dark" ? grey[900] : grey[50]
    };

    &:hover {
      border-color: ${blue[400]};
    }

    &:focus {
      border-color: ${blue[400]};
      box-shadow: 0 0 0 3px ${
        theme.palette.mode === "dark" ? blue[600] : blue[200]
      };
    }

    // firefox
    &:focus-visible {
      outline: 0;
    }
  `
);

export default Edit;
