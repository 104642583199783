import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import BounceLoader from "react-spinners/ClipLoader";

export default function NumericBox({ title, value, color="black" }) {
  const [isBlinking, setIsBlinking] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setIsBlinking((prevIsBlinking) => !prevIsBlinking);
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <Box
      style={{
        padding: "20px",
        margin: "10px",
        boxShadow: "0 5px 10px rgba(0, 0, 0, 0.1)",
        borderRadius: "10px",
      }}
    >
      <div
        style={{
          fontSize: "18px",
          alignItems: "center",
          alignContent: "center",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <span
          style={{
            display: "flex",
            alignContent: "center",
            alignItems: "center",
          }}
        >
          {title} &nbsp;
        </span>

        <br />
        <span style={{ fontSize: "30px", fontWeight: "bold", color:color }}>{value}</span>
      </div>
    </Box>
  );
}
