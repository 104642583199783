import React from "react";


const NotFound = () => {
  return (
    <div className="root" style={{ textAlign: "center", marginTop: "20px" }}>
      <h1 style={{ fontSize: "24px", color: "red" }}>404 - PAGE NOT FOUND!</h1>
      <p style={{ fontSize: "18px" }}>
        The page you are looking for might have been removed had its name
        changed or is temporarily unavailable.
      </p>
      <a href="/" style={{ backgroundColor: "blue", color: "white", padding: "10px 20px", borderRadius: "5px", textDecoration: "none" }}>Go To Homepage</a>
    </div>
  );
};

export default NotFound;
