import * as React from "react";
import { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import { collection, query, where, onSnapshot, doc, deleteDoc, getDocs} from "firebase/firestore";
import { db } from "../../../config/firebase.config"
import { useNavigate } from "react-router-dom";
import { FORWARDER } from "../../../config/constants";
import axios from "axios";
import { useNotification } from "../../../hooks/useNotification";
import { SUCCESS, ERROR } from "../../../config/constants";
import { ResponsiveContainer } from "recharts";

export default function BasicTable() {
    const [echo, setEcho] = useState([]);
    const [webhooks, setWebhooks] = useState([]);
    const navigate = useNavigate();
    const { showNotification } = useNotification();

    const getWebhooks = async () => {
      const webhooksRef = collection(db, "webhooks");
      const snapshot = await getDocs(webhooksRef);
      const webhooks = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setWebhooks(webhooks);
    };

    useEffect(() => {
        const q = query(collection(db, "echo"));
        const unsubscribe = onSnapshot(q, (doc) => {
            const echo = [];
            doc.forEach((doc) => {
                echo.push({ id: doc.id, ...doc.data() });
            });
            setEcho(echo);
        });
        getWebhooks();

        console.log("echo:", echo)
        return () => {
            unsubscribe();
        };


    }, []);

    const handleDelete = async (id) => {
        // Add prompt to confirm delete
        const confirmed = window.confirm("Are you sure you want to delete this item?");
        if (!confirmed) {
            return;
        } else {

        const echoRef = doc(db, "echo", id);
        await deleteDoc(echoRef);
        }
    };

    const handleEdit = async (id) => {
        navigate(`/stress_test/edit/${id}`);
    }   

    const handleSync = async () => {
      const confirmed = window.confirm("Are you sure you want to sync all data?");
      if (!confirmed) {
        return;
      } else {
        try {
          const forwarderUrl = FORWARDER; // Replace with your forwarder URL

          const postData = {
            echo: echo,
            webhooks: webhooks,
          };

          const response = await axios.post(forwarderUrl, postData);
          console.log("Sync successful:", response.data);
          showNotification("Sync successful", SUCCESS);
        } catch (error) {
          console.error("Sync failed:", error);
          showNotification("Sync failed", ERROR);
        }
      }
    };

  return (
    <TableContainer component={Paper} style={{ boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)"}}>
      <div style={{ padding : 20 }}>
      <Typography variant="h4" gutterBottom>
        Stress Test
      </Typography>
      <Stack spacing={2} direction="row">
        <Link to="/stress_test/add">
          <Button variant="contained">Add</Button>
        </Link>
        <Button variant="text" onClick={handleSync}>Sync All</Button>
      </Stack>
      </div>
   
      

      
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Action</TableCell>
            <TableCell>Institution ID</TableCell>
            <TableCell align="right">Faculty</TableCell>
            <TableCell align="right">Date</TableCell>
            <TableCell align="right">Time</TableCell>
            <TableCell align="right">Referring</TableCell>
            <TableCell align="right">Medication</TableCell>
            <TableCell align="right">Resting HR</TableCell>
            <TableCell align="right">Resting SBP</TableCell>
            <TableCell align="right">Resting DBP</TableCell>
            <TableCell align="right">Target HR</TableCell>
            <TableCell align="right">Max Predicted HR</TableCell>
            <TableCell align="right">Percent Max HR</TableCell>
            <TableCell align="right">Max HR</TableCell>
            <TableCell align="right">Max SBP</TableCell>
            <TableCell align="right">Max DBP</TableCell>
            <TableCell align="right">Protocol</TableCell>
            <TableCell align="right">HR x BP</TableCell>
            <TableCell align="right">Billing Code</TableCell>
            <TableCell align="right">Worstcase ST Level</TableCell>
            <TableCell align="right">Worstcase ST Slope</TableCell>
            <TableCell align="right">Total Exercise Time</TableCell>
            <TableCell align="right">METS</TableCell>
            <TableCell align="right">Reasons For Test</TableCell>
            <TableCell align="right">Reasons For Ending Test</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {echo.map((row) => (
            <TableRow
              key={row.name}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                <Grid>
                  <Grid xs={12}>
                    <Button
                      variant="contained"
                      style={{ marginBottom: 5, width: "100%" }}
                      onClick={() => handleEdit(row.id)}
                    >
                      Edit
                    </Button>
                  </Grid>
                  <Grid xs={12}>
                    <Button
                      variant="contained"
                      style={{ marginBottom: 5, width: "100%" }}
                      color="error"
                        onClick={() => handleDelete(row.id)}
                    >
                      Delete
                    </Button>
                  </Grid>
                </Grid>
              </TableCell>
              <TableCell component="th" scope="row">
                {row.institutionID}
              </TableCell>
              <TableCell align="right">{row.faculty}</TableCell>
              <TableCell align="right">{row.date}</TableCell>
              <TableCell align="right">{row.time}</TableCell>
              <TableCell align="right">{row.referring}</TableCell>
              <TableCell align="right">{row.medication}</TableCell>
              <TableCell align="right">{row.restingHR}</TableCell>
              <TableCell align="right">{row.restingSBP}</TableCell>
              <TableCell align="right">{row.restingDBP}</TableCell>
              <TableCell align="right">{row.targetHR}</TableCell>
              <TableCell align="right">{row.maxPredictedHR}</TableCell>
              <TableCell align="right">{row.percentMaxHR}</TableCell>
              <TableCell align="right">{row.maxHR}</TableCell>
              <TableCell align="right">{row.maxSBP}</TableCell>
              <TableCell align="right">{row.maxDBP}</TableCell>
              <TableCell align="right">{row.protocol}</TableCell>
              <TableCell align="right">{row.HRxBP}</TableCell>
              <TableCell align="right">{row.billingCode}</TableCell>
              <TableCell align="right">{row.worstcaseSTLevel}</TableCell>
              <TableCell align="right">{row.worstcaseSTSlope}</TableCell>
              <TableCell align="right">{row.totalExerciseTime}</TableCell>
              <TableCell align="right">{row.mets}</TableCell>
              <TableCell align="right">{row.reasonsForTest}</TableCell>
              <TableCell align="right">{row.reasonsForEndingTest}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
