import React, { useEffect, useState } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";
import Box from "@mui/material/Box";

export default function AgeVersusOutpatient({ rows }) {
  const [data, setData] = useState([]);
    const [counter, setCounter] = useState(0);

  // cluster the age into 5 groups
  //  data =  [
  //     {
  //       name: "< 20",
  //       male: 50,
  //       female: 20,
  //     },
  //     {
  //       name: "20 to 40",
  //       male: 300,
  //       female: 138,
  //     },
  //     {
  //       name: "40 to 60",
  //       male: 480,
  //       female: 238,
  //     },
  //     {
  //       name: "60 >",
  //       male: 780,
  //       female: 308,
  //     },
  //   ]
  useEffect(() => {

      // cluster the age into five group and their gender started from 0-20, 21-40, 41-60, 61-80, 81-100
      let maleAgeGroup = [0, 0, 0, 0, 0];
      let OutpatientTotalMale = [0, 0, 0, 0, 0];
      let femaleAgeGroup = [0, 0, 0, 0, 0];
      let OutpatientTotalFemale = [0, 0, 0, 0, 0];
      let othersAgeGroup = [0, 0, 0, 0, 0];
      let OutpatientTotalOthers = [0, 0, 0, 0, 0];
      rows.forEach((row) => {
        if (row.Gender == "M") {
          var age = parseInt(row.Age);
          if (age < 20) {
            maleAgeGroup[0] += 1;
            OutpatientTotalMale[0] += parseInt(row.Outpatient.length || 0);
          } else if (age < 40) {
            maleAgeGroup[1] += 1;
            OutpatientTotalMale[1] += parseInt(row.Outpatient.length || 0);
          } else if (age < 60) {
            maleAgeGroup[2] += 1;
            OutpatientTotalMale[2] += parseInt(row.Outpatient.length || 0);
          } else if (age < 80) {
            maleAgeGroup[3] += 1;
            OutpatientTotalMale[3] += parseInt(row.Outpatient.length || 0);
          } else {
            maleAgeGroup[4] += 1;
            OutpatientTotalMale[4] += parseInt(row.Outpatient.length || 0);
          }
        } else if (row.Gender == "F") {
          if (age < 20) {
            femaleAgeGroup[0] += 1;
            OutpatientTotalFemale[0] += parseInt(row.Outpatient.length || 0);
            console.log(row.Outpatient.length);
          } else if (age < 40) {
            femaleAgeGroup[1] += 1;
            OutpatientTotalFemale[1] += parseInt(row.Outpatient.length || 0);
          } else if (age < 60) {
            femaleAgeGroup[2] += 1;
            OutpatientTotalFemale[2] += parseInt(row.Outpatient.length || 0);
          } else if (age < 80) {
            femaleAgeGroup[3] += 1;
            OutpatientTotalFemale[3] += parseInt(row.Outpatient.length || 0);
          } else {
            femaleAgeGroup[4] += 1;
            OutpatientTotalFemale[4] += parseInt(row.Outpatient.length || 0);
          }
        } else {
          if (age < 20) {
            othersAgeGroup[0] += 1;
            OutpatientTotalOthers[0] += parseInt(row.Outpatient.length || 0);
          } else if (age < 40) {
            othersAgeGroup[1] += 1;
            OutpatientTotalOthers[1] += parseInt(row.Outpatient.length || 0);
          } else if (age < 60) {
            othersAgeGroup[2] += 1;
            OutpatientTotalOthers[2] += parseInt(row.Outpatient.length || 0);
          } else if (age < 80) {
            othersAgeGroup[3] += 1;
            OutpatientTotalOthers[3] += parseInt(row.Outpatient.length || 0);
          } else {
            othersAgeGroup[4] += 1;
            OutpatientTotalOthers[4] += parseInt(row.Outpatient.length || 0);
          }
        }
      });

      setData([
        {
          name: "< 20",
          male: checkIsNull(OutpatientTotalMale[0], maleAgeGroup[0]),
          female: checkIsNull(OutpatientTotalFemale[0], femaleAgeGroup[0]),
          others: checkIsNull(OutpatientTotalOthers[0], othersAgeGroup[0]),
        },
        {
          name: "20 to 40",
          male: checkIsNull(OutpatientTotalMale[1], maleAgeGroup[1]),
          female: checkIsNull(OutpatientTotalFemale[1], femaleAgeGroup[1]),
          others: checkIsNull(OutpatientTotalOthers[1], othersAgeGroup[1]),
        },
        {
          name: "40 to 60",
          male: checkIsNull(OutpatientTotalMale[2] , maleAgeGroup[2]),
          female: checkIsNull(OutpatientTotalFemale[2], femaleAgeGroup[2]),
          others: checkIsNull(OutpatientTotalOthers[2], othersAgeGroup[2]),
        },
        {
          name: "60 to 80",
          male: checkIsNull(OutpatientTotalMale[3], maleAgeGroup[3]),
          female: checkIsNull(OutpatientTotalFemale[3], femaleAgeGroup[3]),
          others: checkIsNull(OutpatientTotalOthers[3] , othersAgeGroup[3]),
        },
        {
          name: "80 >",
          male: checkIsNull(OutpatientTotalMale[4] , maleAgeGroup[4]),
          female: checkIsNull(OutpatientTotalFemale[4] , femaleAgeGroup[4]),
          others: checkIsNull(OutpatientTotalOthers[4] , othersAgeGroup[4]),
        },
      ]);

      

        // Stop the useEffect after 5 times
        if (counter < 5) {
            setCounter((prevCounter) => prevCounter + 1);
        }

   
  }, [rows, counter]);

  function checkIsNull(numerator, denominator) {
    let result;
    if (
      numerator === undefined ||
      denominator === undefined ||
      isNaN(numerator) ||
      isNaN(denominator) ||
      denominator === 0
    ) {
      result = 0; // If any value is undefined, NaN, or denominator is 0, set result to 0
    } else {
    result = (numerator / denominator).toFixed(2); // Otherwise, perform the division
    }

    return result;
  }

  return (
    <Box
      style={{
        padding: "20px",
        margin: "10px",
        boxShadow: "0 5px 10px rgba(0, 0, 0, 0.1)",
        borderRadius: "10px",
      }}
    >
      <div
        style={{
          fontSize: "25px",
          alignItems: "center",
          alignContent: "center",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        Age versus Outpatient
        <br />
      </div>
      <LineChart
        width={700}
        height={300}
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Line
          type="monotone"
          dataKey="male"
          stroke="#8884d8"
          activeDot={{ r: 8 }}
        />
        <Line type="monotone" dataKey="female" stroke="#82ca9d" />
        <Line type="monotone" dataKey="others" stroke="#144131" />
      </LineChart>
    </Box>
  );
}
