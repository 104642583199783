import * as React from "react";
import { Button, Stack } from "@mui/material";
import { Link } from "react-router-dom";

import rehabData from "../../../store/new_data/rehab_mrn_data.json"
import combinedData from "../../../store/new_data/combined.json";
import DistributionByAgeGroup from "./Visualisation/DistributionByAgeGroup";
import GenderClassification from "./Visualisation/GenderClassification";
import InpatientVersusOutpatient from "./Visualisation/InpatientVersusOutpatient";
import NumericBox from "./Visualisation/NumericBox";
import AgeVersusOutpatient from "./Visualisation/AgeVersusOutpatient";
import AgeVersusInpatient from "./Visualisation/AgeVersusInpatient";
import AgeVersusRehab from "./Visualisation/AgeVersusRehab";

export default function General() {
  const [rows, setRows] = React.useState([]);
  const [rehabPatients, setRehabPatients] = React.useState([]);

  // Cardiac arrest category and keyword
  const cardiacArrestCategoryAndKeyword = {
    "Acute rheumatic fever": [
      "acute",
      "rheumatic",
      "pericarditis",
      "endocarditis",
      "myocarditis",
      "chorea",
    ],
    "Chronic rheumatic heart diseases": [
      "rheumatic",
      "chronic",
      "mitral",
      "stenosis",
      "aortic",
      "tricuspid",
    ],
    "Hypertensive diseases": [
      "hypertension",
      "hypertensive",
      "heart",
      "renal",
      "endocrine",
      "renovascular",
    ],
    "Ischaemic heart diseases": [
      "cardiomyopathy",
      "aneurysm",
      "atherosclerotic",
      "dressler",
      "thrombosis",
      "chordae tendineae",
      "chordae",
      "tendineae",
      "ventricular",
      "atrial septal",
      "atrial",
      "haemopericardium",
      "unstable angina",
      "angina",
      "pectoris",
      "stenocardia",
      "ischaemic",
      "acute myocardial infarction",
      "myocardial",
      "infarction",
    ],
    "Pulmonary heart disease and diseases of pulmonary circulation": [
      "pulmonary",
      "arteriovenous fistula",
      "aneurysm",
    ],
    "Other forms of heart disease": [
      "cardiomegaly",
      "chordae tendineae",
      "heart failure",
      "arrhythmia",
      "depolarization",
      "arrhythmias",
      "atrial fibrillation",
      "atrial",
      "fibrillation",
      "supraventricular",
      "paroxysmal tachycardia",
      "paroxysmal",
      "tachycardia",
      "cardiac",
      "arrest",
      "cardiac arrest",
      "bifascicular",
      "trifascicular",
      "pericarditis",
      "idiopathic",
      "haemopericardium",
      "pericardial",
      "pericardium",
      "endocarditis",
      "nonrheumatic",
      "cardiomyopathy",
      "atrioventricular",
      "fascicular",
    ],
    "Cerebrovascular diseases": [
      "occlusion",
      "stenosis",
      "stroke",
      "cerebral infarction",
      "haemorrhage",
      "intracerebral",
      "subarachnoid",
      "carotid siphon",
      "bifurcation",
      "cerebral",
      "cerebrovascular",
      "subarachnoid",
      "basilar",
      "vertebral",
    ],
    "Diseases of arteries, arterioles and capillaries": [
      "arteries",
      "arterioles",
      "capillaries",
      "capillary",
      "atherosclerosis",
      "aorta",
      "renal",
      "raynaud",
    ],
    "Diseases of veins, lymphatic vessels and lymph nodes, not elsewhere classified": [
      "lymphadenitis",
      "varices",
      "varicose",
      "veins",
      "lympatic",
      "lymph",
      "phlebitis",
      "thrombophlebitis",
    ],
    "Other and unspecified disorders of the circulatory system": [
      "hypotension",
      "circulatory",
    ],
  };

  const cardiacArrestKeyword = Object.values(
    cardiacArrestCategoryAndKeyword
  ).flat();

  React.useEffect(() => {
    const allData = [];
      
    combinedData.map((data) => {
      var inpatientTimeline = data.Inpatient;
      var outpatientTimeline = data.Outpatient;
      allData.push({
        id: data.no,
        MRN: data.MRN,
        Gender: data.Gender,
        Age: data.Age,
        Address: data.Alamat,
        Inpatient: data.Inpatient,
        Outpatient: data.Outpatient,
        Date_deceased: data["Date Deceased"],
        Reason_deceased: data["Reason Deceased"],
        Timeline: [...inpatientTimeline, ...outpatientTimeline],
      });
    });
    setRehabPatients(rehabData.MRN);
    setRows(allData);
  }, []);

  // Trim and convert date to Date object
  const convertDate = (date) => {
    const trimmedDateString = date.trim();
    // Split the string into date and time parts
    var parts = trimmedDateString.split(" ");

    // Extract date parts
    var dateParts = parts[0].split("-");
    var year = parseInt(dateParts[0]);
    var month = parseInt(dateParts[1]) - 1; // Months are 0-based (0 = January, 1 = February, etc.)
    var day = parseInt(dateParts[2]);

    return new Date(year, month, day);
  };

  // Check the first rehab date
  const checkTheFirstRehabDate = (data) => {
    var rehabDate = data.Outpatient;
    var firstClinicDate = null;

    if (rehabDate.length > 0) {
      // Get the first rehab date
      var outpatientData = rehabDate[0];

      // Iterate over the array elements
      for (var i = 0; i < outpatientData.length; i++) {
        var obj = outpatientData[i];
        if ("Date Clinic" in obj) {
          firstClinicDate = convertDate(obj["Date Clinic"]);
          break; // Stop iteration once Admit Date is found
        }
      }

      // Check other array if there is an earlier rehab date
      rehabDate.forEach((value) => {
        for (var i = 0; i < value.length; i++) {
          var obj = value[i];
          if ("Date Clinic" in obj) {
            var date = convertDate(obj["Date Clinic"]);
            if (date < firstClinicDate) {
              firstClinicDate = date;
            }
          }
        }
      });
    }

    return firstClinicDate;
  };

  const checkTheLastRehabDate = (data) => {
    var rehabDate = data.Outpatient;
    var lastClinicDate = null;

    if (rehabDate.length > 0) {
      // Get the first rehab date
      var outpatientData = rehabDate[0];

      // Iterate over the array elements
      for (var i = 0; i < outpatientData.length; i++) {
        var obj = outpatientData[i];
        if ("Date Clinic" in obj) {
          lastClinicDate = convertDate(obj["Date Clinic"]);
          break; // Stop iteration once Admit Date is found
        }
      }

      // Check other array if there is an earlier rehab date
      rehabDate.forEach((value) => {
        for (var i = 0; i < value.length; i++) {
          var obj = value[i];
          if ("Date Clinic" in obj) {
            var date = convertDate(obj["Date Clinic"]);
            if (date > lastClinicDate) {
              lastClinicDate = date;
            }
          }
        }
      });
    }
    return lastClinicDate;
  };

  // Check first Inpatient Date
  const checkFirstInpatientDate = (data) => {
    // using "Admit Date" as the keyword to search for the first inpatient date
    var inpatientDate = data.Inpatient;
    var firstAdmitDate = null;

    if (inpatientDate.length > 0) {
      // Get the first rehab date
      var inpatientData = inpatientDate[0];

      // Iterate over the array elements
      for (var i = 0; i < inpatientData.length; i++) {
        var obj = inpatientData[i];
        if ("Admit Date" in obj) {
          firstAdmitDate = convertDate(obj["Admit Date"]);
          break; // Stop iteration once Admit Date is found
        }
      }

      // Check other array if there is an earlier rehab date
      inpatientDate.forEach((value) => {
        for (var i = 0; i < value.length; i++) {
          var obj = value[i];
          if ("Admit Date" in obj) {
            var date = convertDate(obj["Admit Date"]);
            if (date < firstAdmitDate) {
              firstAdmitDate = date;
            }
          }
        }
      });
    }
    return firstAdmitDate;
  };

  // Check the last Inpatient Date
  const checkLastInpatientDate = (data) => {
    // using "Admit Date" as the keyword to search for the first inpatient date
    var inpatientDate = data.Inpatient;
    var lastAdmitDate = null;

    if (inpatientDate.length > 0) {
      // Get the first rehab date
      var inpatientData = inpatientDate[0];

      // Iterate over the array elements
      for (var i = 0; i < inpatientData.length; i++) {
        var obj = inpatientData[i];
        if ("Admit Date" in obj) {
          lastAdmitDate = convertDate(obj["Admit Date"]);
          break; // Stop iteration once Admit Date is found
        }
      }

      // Check other array if there is an earlier rehab date
      inpatientDate.forEach((value) => {
        for (var i = 0; i < value.length; i++) {
          var obj = value[i];
          if ("Admit Date" in obj) {
            var date = convertDate(obj["Admit Date"]);
            if (date > lastAdmitDate) {
              lastAdmitDate = date;
            }
          }
        }
      });
    }
    return lastAdmitDate;
  };

  function isDateWithinSixMonths(startDate, endDate) {
    // Convert both dates to JavaScript Date objects
    var start = new Date(startDate);
    var end = new Date(endDate);
  
    // Calculate the difference in milliseconds between the two dates
    var difference = end - start;
  
    // Convert milliseconds to months
    var monthsDifference = difference / (1000 * 60 * 60 * 24 * 30.4375);
  
    // Check if the difference is less than 6 months
    return monthsDifference < 6;
  }

  // Check if patient have Inpatient before rehab
  const checkifAnyAdmitDateIsBeforeRehab = (data) => {
    var outpatientFirstDate = checkTheFirstRehabDate(data);
    var inpatientFirstDate = checkFirstInpatientDate(data);

    if (inpatientFirstDate < outpatientFirstDate && rehabPatients.includes(data.MRN)) {
      return true;
    } else {
      return false;
    }
  };

  //Check if patient have Inpatient after rehab
  const checkifAnyAdmitDateIsAfterRehab = (data) => {
    var outpatientFirstDate = checkTheFirstRehabDate(data);
    var inpatientFirstDate = checkFirstInpatientDate(data);

    if (inpatientFirstDate > outpatientFirstDate && rehabPatients.includes(data.MRN)) {
      return true;
    } else {
      return false;
    }
  };

  //Check both latest inpatient and outpatient
  const checkLatestInpatientAndRehabisWithinSixMonths = (data) => {
    var lastInpatientDate = checkLastInpatientDate(data);
    var lastRehabDate = checkTheLastRehabDate(data);

    if( isDateWithinSixMonths(lastRehabDate, lastInpatientDate) && rehabPatients.includes(data.MRN)){
      return true;
    } else {
      return false;
    }
  }


  // Check total admission of inpatient before rehab date - will return number (count)
  const checkAllDateAdmitBeforeRehab = (data) => {
    var outpatientFirstDate = checkTheFirstRehabDate(data);
    var inpatientFirstDate = checkFirstInpatientDate(data);
    var count = 0;

    if (inpatientFirstDate < outpatientFirstDate) {
      var inpatientDate = data.Inpatient;
      inpatientDate.forEach((value) => {
        for (var i = 0; i < value.length; i++) {
          var obj = value[i];
          if ("Admit Date" in obj) {
            var date = convertDate(obj["Admit Date"]);
            if (date < outpatientFirstDate && rehabPatients.includes(data.MRN) ) {
              count = count + 1;
            }
          }
        }
      });
    }
    return count;
  };

  // Check total of inpatient after rehab date - will return number (count)
  const checkAllDateAdmitAfterRehab = (data) => {
    var outpatientFirstDate = checkTheFirstRehabDate(data);
    var inpatientFirstDate = checkFirstInpatientDate(data);
    var count = 0;
    var inpatientDate = data.Inpatient;
    inpatientDate.forEach((value) => {
      for (var i = 0; i < value.length; i++) {
        var obj = value[i];
        if ("Admit Date" in obj) {
          var date = convertDate(obj["Admit Date"]);
          if (date > outpatientFirstDate && rehabPatients.includes(data.MRN) ) {
            count = count + 1;
          }
        }
      }
    });
    return count;
  };


  const checkAllDateAdmitInpatient = (data) => {
    var count = 0;
    var inpatientDate = data.Inpatient;
    inpatientDate.forEach((value) => {
      for (var i = 0; i < value.length; i++) {
        var obj = value[i];
        if ("Admit Date" in obj) {
          var date = convertDate(obj["Admit Date"]);
            count = count + 1;
        }
      }
    });
    return count;
  };

  // Check death by cardiac arrest
  const checkDeathByCardiacArrest = (data) => {
    // use keyword and check if reason deceased contains any of the keyword then return true or false
    var reasonDeceased = data["Reason_deceased"].toLowerCase();
    var count = 0;

    // use filter
    var result = cardiacArrestKeyword.filter((word) =>
      reasonDeceased.includes(word)
    );

    if (result.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  // Check death by other reasons where the Reason_deceased does not contain any of the keyword and is not empty
  const checkDeathByOtherReasons = (data) => {
    var reasonDeceased = data["Reason_deceased"].toLowerCase();
    var count = 0;

    // use filter
    var result = cardiacArrestKeyword.filter((word) =>
      reasonDeceased.includes(word)
    );

    if (result.length > 0) {
      return false;
    } else if (reasonDeceased.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  // Death but having no rehab plan
  const checkDeathWithNoRehab = (data) => {
    var reasonDeceased = data["Reason_deceased"].toLowerCase();
    var count = 0;

    if (reasonDeceased.length > 0 && !rehabPatients.includes(data.MRN)) {
      return true;
    } else {
      return false;
    }
  };

  // Death with rehab history
  const checkDeathWithRehab = (data) => {
    var reasonDeceased = data["Reason_deceased"].toLowerCase();
    var count = 0;

    if (reasonDeceased.length > 0 && rehabPatients.includes(data.MRN)) {
      return true;
    } else {
      return false;
    }
  };

  //Death by cardiac arrest and have rehabilitation record
  const checkDeathByCardiacArrestAndHaveRehab = (data) => {
    if (checkDeathByCardiacArrest(data) && checkDeathWithRehab(data)) {
      return true;
    } else {
      return false;
    }
  };

  // Compare the inpatient Admit Date and rehab date with are the latest one (inpatient the last thing they did)
  const checkDeathByCardiacArrestAndInpatientIsLast = (data) => {
    var lastInpatientDate = checkLastInpatientDate(data);
    var lastRehabDate = checkTheLastRehabDate(data);

    if (
      (lastRehabDate || lastInpatientDate)&&
      checkDeathByCardiacArrest(data)
    ) {
      if(lastRehabDate > lastInpatientDate && rehabPatients.includes(data.MRN) ){
        return false
      }
      return true;
    }
  };

  // Compare the inpatient Admit Date and rehab date with are the latest one (rehab the last thing they did)
  const checkDeathByCardiacArrestAndRehabIsLast = (data) => {
    var lastInpatientDate = checkLastInpatientDate(data);
    var lastRehabDate = checkTheLastRehabDate(data);

    if (
      lastInpatientDate < lastRehabDate &&
      checkDeathByCardiacArrest(data) && rehabPatients.includes(data.MRN)
    ) {
      return true;
    }
  };

  // Death by others and have rehab history at the end of their life
  const checkDeathByOtherReasonsAndHaveRehab = (data) => {
    var lastInpatientDate = checkLastInpatientDate(data);
    var lastRehabDate = checkTheLastRehabDate(data);

    if (checkDeathByOtherReasons(data) && rehabPatients.includes(data.MRN)) {
      return true;
    }
  };

  // Death by others and have inpatient history at the end of their life
  const checkDeathByOtherReasonsAndHaveInpatient = (data) => {
    var lastInpatientDate = checkLastInpatientDate(data);
    var lastRehabDate = checkTheLastRehabDate(data);

    if (checkDeathByOtherReasons(data) && (lastInpatientDate || lastRehabDate) &&  !rehabPatients.includes(data.MRN)) {
      return true;
    }
  };

  return (
    <>
      <Stack spacing={2} direction="row">
        {/* <Link to="/patient_journey/add">
          <Button variant="contained">Add</Button>
        </Link> */}
        <Link to="/patient_journey/view">
          <Button variant="contained">JSON Viewer</Button>
        </Link>
        <Link to="/patient_journey/table_view">
          <Button variant="contained">Table View</Button>
        </Link>
      </Stack>

      <Stack spacing={2} direction="row">
        <NumericBox title="Total Patients" value={rows.length} />

        <NumericBox
          title="Average Age"
          value={(
            rows
              .filter((value) => value.Age.length != "")
              .reduce((a, b) => a + parseInt(b.Age), 0) / rows.length
          ).toFixed(2)}
        />

        <NumericBox
          title="Deceased"
          color="red"
          value={rows.filter((value) => value.Date_deceased.length > 0).length}
        />
        <NumericBox
          title="Alive"
          color="green"
          value={rows.filter((value) => value.Date_deceased.length == 0).length}
        />
        
        <NumericBox
        title="Total of patient actually do rehab"
        color="blue"
        value={rows.filter((value) => rehabPatients.includes(value.MRN)).length}
        />
        
        
      </Stack>

      <Stack spacing={2} direction="row">
      <NumericBox
          title="Patient have inpatient history before Rehab."
          color="orange"
          value={
            rows.filter((value) => checkifAnyAdmitDateIsBeforeRehab(value))
              .length
          }
        />
        <NumericBox
          title="Patient have inpatient history after Rehab"
          color="red"
          value={
            rows.filter((value) => checkifAnyAdmitDateIsAfterRehab(value))
              .length
          }
        />
       
        </Stack>

        <Stack spacing={2} direction="row">
      
        <NumericBox
          title="Average number of inpatient per patient"
          value={(
            rows.reduce((a, b) => a + b.Inpatient.length, 0) / rows.length
          ).toFixed(2)}
        />
        <NumericBox
          title="Average number of outpatient per patient"
          value={(
            rows.reduce((a, b) => a + b.Outpatient.length, 0) / rows.length
          ).toFixed(2)}
        />
        <NumericBox
        title="Average number of actual rehab per patient"
        color="blue"
        value={(
          rows.filter((value) => rehabPatients.includes(value.MRN)).length / rows.length
        ).toFixed(2)}
      />
        
       
        </Stack>
      <Stack spacing={2} direction="row">
        
        <NumericBox
          title="Total of inpatient before rehab (rehab patient only)"
          value={rows
            .reduce((a, b) => a + checkAllDateAdmitBeforeRehab(b), 0)
            .toFixed(2)}
        />

        <NumericBox
          title="Total of inpatient after rehab (rehab patient only)"
          color="green"
          value={rows
            .reduce((a, b) => a + checkAllDateAdmitAfterRehab(b), 0)
            .toFixed(2)}
        />

        
      </Stack>
      <Stack spacing={2} direction={"row"}>

      <NumericBox
          title={"Total of inpatient before rehab per rehab patient"}
          value={(
            rows.reduce((a, b) => a + checkAllDateAdmitBeforeRehab(b), 0) /
            rows.filter((value) => rehabPatients.includes(value.MRN)).length
          ).toFixed(2)}
        />

        <NumericBox
          title={"Total of inpatient after rehab per rehab patient"}
          color="green"
          value={(
            rows.reduce((a, b) => a + checkAllDateAdmitAfterRehab(b), 0) /
            rows.filter((value) => rehabPatients.includes(value.MRN)).length
          ).toFixed(2)}
        /> 

        <NumericBox
          title={"Total of inpatient non-rehab per patient"}
          color="red"
          value={(
            rows.reduce((a, b) => a + checkAllDateAdmitInpatient(b), 0) /
            rows.filter((value) => !rehabPatients.includes(value.MRN)).length
          ).toFixed(2)}
        />
      </Stack>

      <Stack spacing={2} direction={"row"}>
      <NumericBox
          title="Death by cardiac arrest"
          value={
            rows.filter((value) => checkDeathByCardiacArrest(value)).length
          }
        />
        {/* Death by other reasons */}
        <NumericBox
          title="Death by other reasons"
          value={rows.filter((value) => checkDeathByOtherReasons(value)).length}
        />

        {/* Death without rehab */}
        <NumericBox
          title="Death without previous rehab history"
          value={rows.filter((value) => checkDeathWithNoRehab(value)).length}
        />

        {/* Death with rehab */}
        <NumericBox
          title="Death with previous rehab history"
          value={rows.filter((value) => checkDeathWithRehab(value)).length}
        />

        {/* Death by cardiac arrest and have rehabilitation record */}
        <NumericBox
          title="Death by cardiac arrest and have rehab history"
          value={
            rows.filter((value) => checkDeathByCardiacArrestAndHaveRehab(value))
              .length
          }
        />

        <NumericBox 
        title="Death by cardiac arrest and rehab is the latest one"
        value={rows.filter((value) => checkDeathByCardiacArrestAndRehabIsLast(value)).length}
        />
      </Stack>

      <Stack spacing={2} direction="row">

        {/* Death by cardiac arrest and have rehabilitation record but followed by inpatient after rehab */}
        <NumericBox
          title="Death by cardiac arrest and have rehab history but followed by Inpatient after Rehab"
          value={
            rows.filter(
              (value) =>
                checkDeathByCardiacArrestAndHaveRehab(value) &&
                checkAllDateAdmitAfterRehab(value) > 0
            ).length
          }
        />

        {/* Death by cardiac arrest and have rehabilitation record at the end of their life */}
        <NumericBox
          title="Death by cardiac arrest and have rehab history at the end of their life"
          value={
            rows.filter((value) =>
              checkDeathByCardiacArrestAndRehabIsLast(value)
            ).length
          }
        />

        {/* Death by cardiac arrest and have inpatient record at the end of their life */}
        <NumericBox
          title="Death by cardiac arrest and have inpatient/Outpatient history at the end of their life"
          value={
            rows.filter((value) =>
              checkDeathByCardiacArrestAndInpatientIsLast(value)
            ).length
          }
        />
      </Stack>

      <Stack spacing={2} direction="row">
        {/* Death by cardiac arrest and have rehabilitation record at the end of their life */}
        <NumericBox
          title="Death by others and have rehab history at the end of their life"
          value={
            rows.filter((value) => checkDeathByOtherReasonsAndHaveRehab(value))
              .length
          }
        />

        {/* Death by cardiac arrest and have inpatient record at the end of their life */}
        <NumericBox
          title="Death by others and have inpatient/Outpatient history at the end of their life"
          value={
            rows.filter((value) =>
              checkDeathByOtherReasonsAndHaveInpatient(value)
            ).length
          }
        />

        {/* Death by cardiac arrest and have inpatient history */}
        <NumericBox
          title="Death by Cardiac Arrest and have inpatient history"
          value={
            rows.filter(
              (value) =>
                checkDeathByCardiacArrest(value) && value.Inpatient.length > 0
            ).length
          }
        />

        {/* Death by cardiac arrest and have rehab history */}
      </Stack>

      <Stack spacing={2} direction="row">
        {/* Death by others and have rehabilitation record at the end of their life */}
        <NumericBox
          title="Death by other reasons and have rehab history "
          value={
            rows.filter(
              (value) =>
                checkDeathByOtherReasons(value) && rehabPatients.includes(value.MRN)
            ).length
          }
        />

        {/* Death by others and have no rehab history */}
        <NumericBox
          title="Death by other reasons and have no rehab history"
          value={
            rows.filter(
              (value) =>
                checkDeathByOtherReasons(value) && !rehabPatients.includes(value.MRN)
            ).length
          }
        />


        {/* Death by others and have inpatient history */}
        <NumericBox
          title="Dont have rehab history"
          value={rows.filter((value) => !rehabPatients.includes(value.MRN)).length}
        />
      </Stack>

      <Stack spacing={2} direction="row">
        <InpatientVersusOutpatient
          inpatient={rows.filter((value) => value.Inpatient.length > 0).length}
          outpatient={
            rows.filter((value) => value.Outpatient.length > 0).length
          }
          rehab={
            rows.filter((value) => rehabPatients.includes(value.MRN)).length
          }
        />
        <GenderClassification
          male={rows.filter((value) => value.Gender == "M").length}
          female={rows.filter((value) => value.Gender == "F").length}
        />
      </Stack>
      <Stack spacing={2} direction="row">
        <DistributionByAgeGroup
          rows={rows}
        />
        <AgeVersusOutpatient rows={rows} />

        {/* By location clustering */}
      </Stack>
      <Stack spacing={2} direction="row">
        <AgeVersusInpatient rows={rows} />
        <AgeVersusRehab rows={rows.filter((value) => rehabPatients.includes(value.MRN))} />
      </Stack>
    </>
  );
}
