import React from 'react';

const View = () => {
    // Your code for viewing items goes here

    return (
        <div>
           Hello there
        </div>
    );
};

export default View;
