import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import {
  Dashboard,
  Login,
  NotFound,
  StressTest,
  StressTestAdd,
  Draft,
  Register,
  ForgotPassword,
  StressTestEdit,
  StressTestView,
  Settings,
  PatientJourney,
  PatientJourneyAdd,
  PatientJourneyEdit,
  PatientJourneyView,
  PatientJourneyViewAll
} from "./../components/pages";
// import Dashboard from "../components/pages/Dashboard/Dashboard";
import ProtectedRouting from "./ProtectedRouting";
// import Login from "../components/pages/Login/Login";
import { useSelector } from "react-redux";
import { useAuth } from "../config/auth";

const ApplicationRoutes = () => {
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  return (
    <Routes>
      <Route
        path="/login"
        element={isLoggedIn ? <Navigate to="/" /> : <Login />}
      />
      {/* protected routes */}
      <Route path="/" element={<ProtectedRouting />}>
        {/* default */}
        <Route path="" element={<Navigate to="dashboard" />} />
        {/* pages */}
        <Route path="dashboard" element={<Dashboard />} />

        {/* Stress Test */}
        <Route path="/stress_test" element={<StressTest />} />
        <Route path="/stress_test/add" element={<StressTestAdd />} />
        <Route path="/stress_test/edit/:id" element={<StressTestEdit />} />
        <Route path="/stress_test/view/:id" element={<StressTestView />} />

        {/* Patient Journey */}
        <Route path="/patient_journey" element={<PatientJourney />} />
        <Route path="/patient_journey/add" element={<PatientJourneyAdd />} />
        <Route path="/patient_journey/edit/:id" element={<PatientJourneyEdit />} />
        <Route path="/patient_journey/table_view" element={<PatientJourneyView />} />
        <Route path="/patient_journey/view" element={<PatientJourneyViewAll />} />

        {/* Draft */}

        <Route path="/draft" element={<Draft />} />
        <Route path="/settings" element={<Settings />} />
      </Route>
      
      {/* 404 */}
      <Route path="/register" element={<Register />} />
      <Route path="/forgot_password" element={<ForgotPassword />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default ApplicationRoutes;
